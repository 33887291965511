
            @import 'src/styles/mixins.scss';
        
.mainSection {
    margin-bottom: 70px;
    h2 {
        color: #025985;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        max-width: 1007px;

        @include lg {
            font-size: 25px;
            line-height: 50px;
        }
    }
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;

        background-color: #f4ffff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -10;
    }
}
